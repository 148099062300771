import PropTypes from 'prop-types';
import React from 'react';
import NavBar from './NavBar';
import './Home.css';
import $ from 'jquery';
import { Line } from './Line';
let data = require('./data.json');
let data_fiction = require('./data_fiction.json');
import { animateScroll as scroll } from 'react-scroll'
import { isMobile } from "react-device-detect";
import Player from './Player';
import { Cursor } from './Cursor';

export default class Projects extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      seconds: 0,
      state: this.props.state,
      left: 0,
      top: 0,
      showVideo: false,
      number: this.props.current_video,
      current: this.props.current_video,
      projects: this.props.projects,
      current_project: this.props.current_project,
      projectTransition: 'projectTransitionLeftOff',
      interval: true,
      crossClick: false,
      lang: navigator.language.slice(0, 2),
    };

  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.state !== this.props.state) {
      return this.setState({current_project: this.props.projects[this.state.current], projects: this.props.projects})
    } else {
      return null
    }
  }

  transition(project, direction) {
    const { state } = this.props
    const bgColor = state == 'fiction' ? 'bgWhite' : 'bgBlack'


    // this.setState({projectTransition:  `${direction == 'next' ? 'projectTransitionLeft' :'projectTransitionRight'}`})
    //
    // setTimeout(() => {
    //   this.setState({projectTransition:  `${direction == 'next' ? 'projectTransitionLeftOn' :'projectTransitionRightOn'} ${bgColor}`})
    // }, 50)

    // setTimeout(() => {
    //   this.setState({projectTransition:  `${direction == 'next' ? 'projectTransitionRightOff' : 'projectTransitionLeftOff'} ${bgColor}`})
    // }, 350)

    setTimeout(() => {
      this.setState({current_project: project})
    }, 250)

    // setTimeout(() => {
    //   this.setState({projectTransition:  `${direction == 'next' ? 'projectTransitionLeft' : 'projectTransitionRight'} ${bgColor}`})
    // }, 650)
  }

  addSecond = () => {
    if(this.state.showVideo || !this.state.interval) return null

    if (this.state.seconds == 10) {
      if(this.state.number == this.state.projects.length - 1) {
        this.setState({number: 0, seconds: 0, current: 0}, () => this.transition(this.state.projects[this.state.current], 'next') )
      } else {
        this.setState({number: this.state.number + 1, seconds: 0, current: this.state.current + 1}, () => this.transition(this.state.projects[this.state.current], 'next') )
      }
    }

    this.setState({
      seconds: this.state.seconds + 1
    });
  }

  componentDidMount() {
     this.intervalId  = setInterval(this.addSecond, 1000);

     if(this.props.talent) {
       this.props.goToPage(2)
     }
   }

 componentWillUnmount() {
   clearInterval(this.intervalId);
 }

 renderVideo() {
   return(
    <div style={styles.video}>
      <div onClick={() => this.setState({showVideo: false})}>x Close</div>
      <iframe style={{width: '100%', height: '100%'}} src="https://player.vimeo.com/video/94098141?title=0&byline=0&portrait=0" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
   )
 }

 next() {
   const { number, current} = this.state

   this.setState({current: current > this.state.projects.length - 2 ? 0 : current + 1, number: number > this.state.projects.length - 2 ? 0 : number + 1, seconds: 0},
     () => this.transition(this.state.projects[this.state.current], 'next')
   )
 }

 previous() {
   const { number, current} = this.state

   this.setState({current: current < 1 ? this.state.projects.length - 1 : current - 1, number: number < 1 ? this.state.projects.length - 1 : number - 1, seconds: 0},
     () => this.transition(this.state.projects[this.state.current], 'previous')
   )
 }

 updateVideo() {
   const { current, current_project } = this.state

   if(current_project.background_video_url) {
     this.props.updateCurrent(current);
     this.props.setId(current_project.background_video_url);
     this.props.setVideo(true)
   }
 }

 renderVideoOrBackground() {
   const { url } = this.state.current_project.video
   const { current_project } = this.state

   if(url) {
     return(
       <video key={url} className='videoTag' autoPlay loop muted playsInline disablepictureinpicture="true" preload="auto" style={{position: 'absolute', left: 0, right: 0, top: '100vh', height: '100%', width: '100%', objectFit: 'cover', opacity: 0.8}}>
        <source src={current_project.video.url.replace('/upload', '/upload/q_60')} type='video/mp4' />
       </video>
     )
   } else {
     return(
       <div key={current_project.photo.url} style={{background: 'url(' + current_project.photo.url.replace('/upload', '/upload/f_auto,q_auto') + ')', position: 'absolute', left: 0, right: 0, top: 0, height: '100%', opacity: 0.9}} className="bg-mask"/>
     )
   }
 }

  render() {
    const { number, seconds, current, height, current_project, crossClick, lang } = this.state
    const { state, left, top, word } = this.props
    const fr = lang === 'fr'

    let image
    if(state == 'fiction') {
      image = require("./../../../../assets/images/arrow_w.png")
    } else {
      image = require("./../../../../assets/images/arrow_b.png")
    }

    let cross
    if(state == 'fiction') {
      cross = require("./../../../../assets/images/cross_w.png")
    } else {
      cross = require("./../../../../assets/images/cross_b.png")
    }

    let arrow_right
    if(state == 'fiction') {
      arrow_right = require("./../../../../assets/images/right_yellow_mobile.png")
    } else {
      arrow_right = require("./../../../../assets/images/right_green_mobile.png")
    }

    let arrow_left
    if(state == 'fiction') {
      arrow_left = require("./../../../../assets/images/left_yellow_mobile.png")
    } else {
      arrow_left = require("./../../../../assets/images/left_green_mobile.png")
    }

    const color = state == 'fiction' ? 'white' : 'black'

    return(
        <div
          onMouseEnter={() => this.setState({interval: true})}
          onMouseLeave={() => this.setState({interval: false})}
          id="projects"
          style={{flex: 1, height: '100%'}}>
          <div className={this.state.projectTransition}/>
          {this.renderVideoOrBackground()}
          <div style={{background: state == 'studio' ? 'white' : 'black', zIndex: -6}} className="fix-bg"/>
          <div style={{flex: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'black'}}>
            <div style={{flex: 2}}/>
            <div style={{flex: 4, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <div
                style={{ zIndex: 10, color: state == 'studio' ? 'black' : 'white'}}
                onClick={() => this.updateVideo()}
                onMouseEnter={() => current_project.background_video_url ? this.props.handler(fr ? 'voir' : 'play') : null}
                onMouseLeave={() => this.props.handler('')}
                >
                <div style={styles.medium}>{current_project.brand}</div>
                <div style={styles.big}>{current_project.name}</div>
                <div style={styles.medium}>{current_project.category}{current_project.category && ' · '}{current_project.type}{current_project.type && current_project.hashtag && ' · '}{current_project.hashtag}{current_project.hashtag && current_project.year && ' · '}{current_project.year}</div>
              </div>
            </div>
            <div style={{flexDirection: 'row', display: 'flex', flex: 2, alignItems: 'flex-end', marginBottom: 50}} className="line-wrapper">
              <Line number={number} current={0} seconds={seconds} color={color}/>
              <Line number={number} current={1} seconds={seconds} color={color}/>
              <Line number={number} current={2} seconds={seconds} color={color}/>
              <Line number={number} current={3} seconds={seconds} color={color}/>
              <Line number={number} current={4} seconds={seconds} color={color}/>
            </div>
          </div>
          {!this.props.open && <div
            onClick={() => this.next()}
            style={{position: 'absolute', top: '140vh', left: '75vw', height: '60vh', right: 0}}
            onMouseEnter={() => this.props.handler('next')}
            onMouseLeave={() => this.props.handler('')}>
            </div>
          }
          {!this.props.open && <div
            onClick={() => this.props.goToPage(1)}
            style={{position: 'absolute', top: '175vh', right: '25vw', bottom: 0, left: '25vw'}}
            onMouseEnter={() => this.props.handler('down')}
            onMouseLeave={() => this.props.handler('')}/>}
          {!this.props.open &&  <div
            onClick={() => this.previous() }
            style={{position: 'absolute', top: '140vh', right: '75vw', height: '60vh', left: 0}}
            onMouseEnter={() => this.props.handler('previous')}
            onMouseLeave={() => this.props.handler('')}>
            </div>
          }

          {!this.props.open && <div
            onClick={() => this.props.goToPage(2)}
            style={{position: 'absolute', left: '90vw', top: '190vh', height: '10vh', right: 0}}
            onMouseEnter={() => this.props.handler('talents')}
            onMouseLeave={() => this.props.handler('')}>
            <img style={{width: 6, height: 25, position: "absolute", right: 40, bottom: 40}} src={image}/>
            </div>
          }
          {!this.props.open &&  <div
            onClick={() => {
              this.setState({crossClick: true});
              this.props.goToPage(4);
              setTimeout(() => {
                this.setState({crossClick: false})
              }, 1500)
            }}
            style={{position: 'absolute', right: '90vw', top: '190vh', height: '10vh', left: 0}}
            onMouseEnter={() => this.props.handler('contact')}
            onMouseLeave={() => this.props.handler('')}>
            <img style={{width: 20, height: 20, transform: crossClick ? 'rotate(-45deg)' : 'rotate(0deg)', position: "absolute", left: 40, bottom: 40}} src={cross}/>
            </div>
          }

            {isMobile && <div
              onClick={() => this.next()}
              style={{position: 'absolute', top: "100vh", right: 0, display: 'flex', alignItems: 'center', height: '100%'}}>
                <img style={{width: 20, height: 40, position: "absolute", right: 0}} src={arrow_right}/>
              </div>
            }
            {isMobile && <div
              onClick={() => this.previous() }
              style={{position: 'absolute', top: "100vh", left: 0, display: 'flex', alignItems: 'center', height: '100%'}}>
                <img style={{width: 20, height: 40, position: "absolute", left: 0}} src={arrow_left}/>
              </div>
            }
        </div>
    )
  }
}

const styles = {
  medium: {
    fontFamily: 'BebasNeue Regular',
    fontSize: '3vw',
    paddingTop: isMobile ? 10 : 0,
    paddingBottom: 10,
  },
  big: {
    fontSize: '9vw',
    paddingTop: isMobile ? 10 : 0,
    paddingBottom: 10,
  },
  video: {
    position: 'fixed',
    width: '80vw',
    height: '80vh',
  }
}
