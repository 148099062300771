import PropTypes from 'prop-types';
import React from 'react';
import './Welcome.css';
import { isBrowser } from "react-device-detect";

export default class StudioOrFiction extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      left: null,
      top: null,
      color: 'yellow',
      width: 0,
      height: 0,
      landscape: false,
      show: false,
      lang: navigator.language.slice(0, 2),
      loop: false,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    if(!isBrowser) {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    setTimeout(() => {
      this.setState({show: true})
    }, 4000)

    setTimeout(() => {
      this.setState({loop: true})
    }, 19000)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    if(window.innerWidth > window.innerHeight) {
      this.setState({landscape: true})
    }
  }

  fiction() {
    window.location.assign("/home?state=fiction")
  }

  studio() {
    window.location.assign("/home?state=studio")
  }

  render() {
    const { show, lang, loop } = this.state
    const fr = lang === 'fr'

    return(
      <div
        className="video-presentation studio_or_fiction"
        onMouseMove={(result) => this.setState({left: result.pageX - 30, top: result.pageY - 30})}
        style={{height: '100vh', background: this.state.bgColor, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <video key={loop} loop={loop} className='videoTag' autoPlay muted playsInline style={{position: 'absolute', left: 0, right: 0, top: 0, height: '100%', width: '100%', objectFit: 'cover'}}>
          <source src={loop ? require("../../../../assets/videos/loop.mp4") : require("../../../../assets/videos/background_loop.mp4")} type='video/mp4' />
        </video>

        {show && isBrowser && <div className={"cursor-enter"} style={{left: this.state.left, top: this.state.top, zIndex: 30000, background: this.state.color, color: 'black'}}>{fr ? 'ENTRER' : 'ENTER'}</div>}
        {show && isBrowser && <div className={"cursor-enter"} style={{left: this.state.left, top: this.state.top, zIndex: 30000, background: this.state.color, color: 'black'}}>{fr ? 'ENTRER' : 'ENTER'}</div>}

        <div className={this.state.textLeft} style={{position: 'absolute', top: 0, right: '50vw', bottom: 0, background: 'white', zIndex: 20}}/>
        <div className={this.state.textRight} style={{position: 'absolute', width: '5vw', top: 0, right: '45vw', bottom: 0, zIndex: 20}}/>

        {show && <div
          className={"enter_text"}
          style={{left: '50vw', right: 0}}
          onMouseEnter={() => this.setState({color: '#FEFF50', textColor: 'white'})}
          onClick={() => this.fiction()}>
          {isBrowser ?
            'CULTURE'
            :
            <img style={{width: 120}} src={require("./../../../../assets/images/culture_mobile.png")}/>
          }
        </div>}
        {show && <div
          className={"enter_text"}
          onMouseEnter={() => this.setState({color: '#83FF83', textColor: 'black'})}
          style={{right: '50vw', left: 0}}
          onClick={() => this.studio()}>
          {isBrowser ?
            'NATURE'
            :
            <img style={{width: 120}} src={require("./../../../../assets/images/nature_mobile.png")}/>
          }
        </div>}

        {!isBrowser && !this.state.landscape &&
          <div className="warning">
            <img className="rotate-img" src={require("./../../../../assets/images/rotate.png")}/>
            <div><span>Please rotate your phone to</span> <span className="light">lanscape mode</span> <span>for a better experience.</span></div>
          </div>
        }
      </div>
    )
  }
}
