import ReactOnRails from 'react-on-rails';

import Home from '../bundles/HelloWorld/components/Home';
import StudioOrFiction from '../bundles/HelloWorld/components/StudioOrFiction';
import Talent from '../bundles/HelloWorld/components/Talent';


// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  Home,
  StudioOrFiction,
  Talent,
});
