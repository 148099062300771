import PropTypes from 'prop-types';
import React from 'react';
import './Home.css';
import {
  isMobile
} from "react-device-detect";

export default class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      state: this.props.state,
      menu: 'hideMenu',
      bottom: 0,
      lang: navigator.language.slice(0, 2),
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({bottom: 20})
    }, 1500)
    setTimeout(() => {
      this.setState({bottom: 0})
    }, 2000)

    setTimeout(() => {
      this.setState({bottom: 15})
    }, 2500)
    setTimeout(() => {
      this.setState({bottom: 0})
    }, 3000)

    setTimeout(() => {
      this.setState({bottom: 20})
    }, 7000)
    setTimeout(() => {
      this.setState({bottom: 0})
    }, 7500)

    setTimeout(() => {
      this.setState({bottom: 15})
    }, 8000)
    setTimeout(() => {
      this.setState({bottom: 0})
    }, 8500)
  }

  renderMenu() {
    const { open, state } = this.state
    const cross = state == 'fiction' ? require("./../../../../assets/images/cross_white.png") : require("./../../../../assets/images/cross.png")

    if(this.props.close) {
      return(
        <div
          className="btnMenu">
          <img style={{width: 15, height: 15}} src={cross}/>
        </div>
      )
    }

    if(open) return(
      <div className="btnMenu">
        <div style={{width: 35, border: state == 'studio' ? '1px solid black' : '1px solid white', margin: 5, transform: 'rotate(-45deg)', position: 'relative', top: 5, transition: 'transform 0.5s ease'}}/>
        <div className="btnMenuBottomBar" style={{width: 35, border: state == 'studio' ? '1px solid black' : '1px solid white', margin: 5, transform: 'rotate(45deg)', position: 'relative', bottom: 7, transition: 'transform 0.5s ease'}}/>
      </div>
    )

    return(
      <div className="btnMenu">
        <div style={{width: 35, border: state == 'studio' ? '1px solid black' : '1px solid white', margin: 5}}/>
        <div className="btnMenuBottomBar" style={{width: 20, border: state == 'studio' ? '1px solid black' : '1px solid white', margin: 5}}/>
      </div>
    )
  }

  renderMenuContent() {
    const  { state } = this.state
    const  { contentItem, contentItemBlack } = styles

    if(!this.state.open) return null

    return(
      <div className={this.state.menu} style={{flexDirection: 'row', display: 'flex', flex: 1, padding: 50}}>
        <div style={styles.contentLeft}>
          <div className="menuContentWrapper" style={{lineHeight: isMobile ? "30px" : "100px", justifyContent: isMobile ? 'center' : 'flex-end'}}>
            <div style={{flexDirection: 'row', display: 'flex'}}>
              <a style={{fontSize: isMobile ? 30 : 120}}  onClick={() => this.handleClick(0)} className={state == 'fiction' ? 'contentItem' : 'contentItemBlack'}>MANIFESTE</a>
            </div>
            <div style={{flexDirection: 'row', display: 'flex'}}>
              <a style={{fontSize: isMobile ? 30 : 120}}  onClick={() => this.handleClick(1)} className={state == 'fiction' ? 'contentItem' : 'contentItemBlack'}>FILMS</a>
            </div>
            <div style={{flexDirection: 'row', display: 'flex'}}>
              <a style={{fontSize: isMobile ? 30 : 120}}  onClick={() => this.handleClick(2)} className={state == 'fiction' ? 'contentItem' : 'contentItemBlack'}>TALENTS</a>
            </div>
            <div style={{flexDirection: 'row', display: 'flex'}}>
              <a style={{fontSize: isMobile ? 30 : 120}}  onClick={() => this.handleClick(3)} className={state == 'fiction' ? 'contentItem' : 'contentItemBlack'}>SAVOIR-FAIRE</a>
            </div>
            <div style={{flexDirection: 'row', display: 'flex'}}>
              <a style={{fontSize: isMobile ? 30 : 120}}  onClick={() => this.handleClick(4)} className={state == 'fiction' ? 'contentItem' : 'contentItemBlack'}>CONTACT</a>
            </div>
          </div>
        </div>
        <div style={state == 'fiction' ? styles.contentRight : styles.contentRightBlack}>
          <a href="mailto:mystory@goodseed.com" className="menuEmail" style={{color: state == 'fiction' ? 'white' : 'black'}}>MYSTORY@GOODSEED.COM</a>
          <div>+ 33 (0)9 54 31 61 48</div>
        </div>
      </div>
    )
  }

  handleClick(page) {
    if(this.props.close) {
      return window.location.assign(`/home?state=${this.state.state}&talent=true`)
    }

    setTimeout(() => {
      this.setState({menu: !this.state.open ? 'hideMenu' : 'showMenu'})
    }, 400)

    this.props.setNav(!this.state.open)
    this.setState({open: !this.state.open})
    const container = document.getElementById('nav-container')
    container.classList.toggle('is-nav-open')

    this.props.goToPage(page)
  }

  render() {
    const other_state = this.state.state == 'fiction' ? 'studio' : 'fiction'
    const { container, containerBlack, openedMenu, closedMenu, menu, containerWhite } = styles
    const fiction = require("./../../../../assets/images/culture.png")
    const studio = require("./../../../../assets/images/nature.png")
    const { state, open, lang} = this.state
    const fr = lang === 'fr'

    return(
      <div onMouseEnter={() => this.props.handler('')} style={open ? (state == 'studio' ? containerWhite : containerBlack) : container} id="nav-container" className="nav-container">
        <nav className="nav">
          <div style={menu}>
            <div style={{flex: 1}}>
              <img
                key={state == 'fiction' ? fiction : studio}
                onClick={() => this.props.goToPage(0)}
                style={{height: isMobile ? 30 : 40, width: isMobile ? 80 : 140, paddingLeft: isMobile ? 20 : 40, paddingTop: isMobile ? 20 : 40}} src={state == 'fiction' ? fiction : studio}/>
            </div>
            {!this.props.close &&
            <div
              style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}
              onClick={() => {this.props.updateState(other_state); this.setState({state: other_state})}}>
              <div
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', transition: 'bottom 0.5s ease', bottom: this.state.bottom}}
                onMouseLeave={() => this.props.handler('')}
                onMouseEnter={() => this.props.handler(fr ? 'changer' : 'switch')}>
                <div className={this.state.state == 'fiction' ? "circle left" :  "circle right"} style={{zIndex: 5, background: this.state.state == 'fiction' ? '#FEFF50' : '#83FF83'}}/>
                <div className="circle-border" style={{background: 'transparent', borderColor: this.state.state == 'studio' ? 'black' : 'white'}}/>
              </div>
            </div>}
            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center'}}>
              <button
                onMouseLeave={() => this.props.handler('')}
                onMouseEnter={() => this.props.handler(this.props.close ? 'back' : (open ? fr ? 'fermer' : 'close' : 'menu' ))}
                style={open ? openedMenu : closedMenu} onClick={() => this.handleClick()}>{this.renderMenu()}</button>
            </div>
          </div>
          {this.renderMenuContent()}
        </nav>
      </div>
    )
  }
}

const styles = {
  state: {
    fontSize: 20,
    textAlign: 'center',
    top: -55,
    position: 'relative',
  },
  container: {
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    background: 'transparent',
  },
  containerWhite: {
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    background: 'white',
  },
  containerBlack: {
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    background: 'black',
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1920,
  },
  closedMenu: {
    border: 'none',
    background: 'transparent',
    cursor: 'none',
    padding: isMobile ? 0 : 20,
    paddingRight: 20
  },
  openedMenu: {
    border: 'none',
    background: 'transparent',
    color: 'white',
    cursor: 'none',
    padding: isMobile ? 0 : 20,
    paddingRight: 20
  },
  contentRight: {
    color: 'white',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: isMobile ? 17 : 26,
  },
  contentRightBlack: {
    color: 'black',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: isMobile ? 17 : 26,
  },
  contentLeft: {
    color: 'white',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentItem: {
    color: 'white',
    textDecoration: 'none',
    display: 'block',
    fontSize: 125,
  },
  contentItemBlack: {
    color: 'black',
    textDecoration: 'none',
    display: 'block',
    fontSize: 125,
  }
}
