import PropTypes from 'prop-types';
import React from 'react';
import NavBar from './NavBar';
import { animateScroll as scroll } from 'react-scroll'
import {
  isMobile
} from "react-device-detect";

export default class Manifeste extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: this.props.state,
      step: 1,
      lineOne: 'hideLine',
      lineTwo: 'hideLine',
      lineThree: 'hideLine',
      lineFour: 'hideLine',
      lineFive: 'hideLine',
      lineThin: 'hideLine',
      lineThick: 'hideLine',
      interval: false,
      seconds: 0,
      animated: false,
      crossClick: false,
      lang: navigator.language.slice(0, 2)
    }
    this.animateManifeste()
  }

  addSecond = () => {
    if(!this.state.interval) return null

    if (this.state.seconds == 8) {
      if(this.state.step == 4) {
        this.setState({step: 1, seconds: 0})
      } else {
        this.setState({step: this.state.step + 1, seconds: 0})
      }
    }

    this.setState({
      seconds: this.state.seconds + 1
    });
  }

  componentDidMount() {
   setInterval(this.addSecond, 1000);

   isMobile && this.animateManifeste()
 }

 getSnapshotBeforeUpdate(prevProps, prevState) {
   // Are we adding new items to the list?
   // Capture the scroll position so we can adjust scroll later.
   if (prevState.step != this.state.step) {
     return this.animateManifeste()
   }
   return null;
 }

  renderLineOne() {
    const { lang } = this.state
    const { state } = this.props
    const fr = lang === 'fr'

    let text

    switch (this.state.step) {
      case 2:
      state == 'studio' ?
        text = (<div><span className={`${this.state.lineThin} light`}>{fr ? 'NOTRE REGARD EST PORTÉ SUR' : 'OUR VISION IS DIRECTED TOWARDS'}</span></div>)
        :
        text = (<div><span className={`${this.state.lineThin} light`}>{fr ? 'NOTRE ATTENTION EST PORTÉE SUR' : 'OUR ATTENTION IS FOCUSED ON'}</span></div>)
        break
      default:
      state == 'studio' ?
        text = (<div><span className={`${this.state.lineThin} light`}>{fr ? 'NOUS PRODUISONS DES FILMS' : 'WE PRODUCE'}</span> <span className={this.state.lineThick}>{!fr && 'SUSTAINABLE'}</span> <span className={`${this.state.lineThin} light`}>{!fr && 'FILMS'}</span> </div>)
        :
        text = (<div><span className={`${this.state.lineThin} light`}>{fr ? 'NOUS PRODUISONS DES' : 'WE PRODUCE ORIGINAL AND'}</span> <span className={this.state.lineThick}>{fr && 'FILMS'}</span> </div>)
        break
    }
    return text
  }

  renderLineTwo() {
    const { lang } = this.state
    const { state } = this.props
    const fr = lang === 'fr'

    let text

    switch (this.state.step) {
      case 2:
      state == 'studio' ?
        text = (<div><span className={`${this.state.lineThin} light`}>{fr ? 'LE' : 'THE'}</span> <span className={this.state.lineThick}>{fr ? 'VIVANT' : 'LIVING'}</span> <span className={`${this.state.lineThin} light`}>{fr ? 'ET SON' : 'AND THE'}</span> <span className={this.state.lineThick}>{fr ? 'ENVIRONNEMENT.' : 'ENVIRONMENT.'}</span> </div>)
        :
        text = (<div><span className={`${this.state.lineThin} light`}>{fr ? 'UNE NOUVELLE' : 'A GROWING'}</span> <span className={this.state.lineThick}>GENERATION</span> <span className={`${this.state.lineThin} light`}>{fr ? 'DE' : 'OF'}</span> <span className={this.state.lineThick}>TALENTS.</span> </div>)
        break
      default:
      state == 'studio' ?
        text = (<div><span className={`${this.state.lineThin} light`}>{!fr && 'WITH '}</span><span className={this.state.lineThick}>{fr ? 'RESPONSABLES' : 'POSITIVE'}</span> <span className={`${this.state.lineThin} light`}>{fr ? 'À IMPACT' : 'IMPACT.'}</span> <span className={this.state.lineThick}>{fr && 'POSITIF.'}</span></div>)
        :
        text = (<div><span className={this.state.lineThick}>{fr ? 'D\'AUTEUR' : 'DEMANDING ARTHOUSE'}</span> <span className={`${this.state.lineThin} light`}>{fr ? 'ORIGINAUX ET' : 'MOVIES.'}</span> <span className={this.state.lineThick}>{fr && 'EXIGEANTS.'}</span></div>)
        break
      }
      return text
  }

  animateManifeste() {
    this.setState({lineThin: 'hideLine', lineThick: 'hideLine'})
    setTimeout(() => {
      this.setState({lineThin: 'showLine'})
    }, 100)
    setTimeout(() => {
      this.setState({lineThick: 'showLine'})
    }, 300)
    return null
  }

  render() {
    const { step, interval, seconds, crossClick } = this.state
    const { state } = this.props

    let image
    if(state == 'fiction') {
      image = require("./../../../../assets/images/arrow_w.png")
    } else {
      image = require("./../../../../assets/images/arrow_b.png")
    }

    let cross
    if(state == 'studio') {
      cross = require("./../../../../assets/images/cross_b.png")
    } else {
      cross = require("./../../../../assets/images/cross_w.png")
    }

    let arrow_right
    if(state == 'fiction') {
      arrow_right = require("./../../../../assets/images/right_yellow_mobile.png")
    } else {
      arrow_right = require("./../../../../assets/images/right_green_mobile.png")
    }

    let arrow_left
    if(state == 'fiction') {
      arrow_left = require("./../../../../assets/images/left_yellow_mobile.png")
    } else {
      arrow_left = require("./../../../../assets/images/left_green_mobile.png")
    }

    return(
      <div
        id="manifeste"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh', background: state == 'fiction' ? 'black' : 'white'}}>
        <video className='videoTag' autoPlay loop muted playsInline disablepictureinpicture="true" preload="auto" style={{position: 'absolute', left: 0, right: 0, height: '100%', width: '100%', objectFit: 'cover', opacity: 0.8}}>
          <source src={this.props.manifeste.replace('/upload', '/upload/q_60')} type='video/mp4' />
        </video>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', flex: 1, zIndex: 2}}>
          <div style={{flex: 4, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', fontSize: '7vw', color: this.props.state == 'fiction' ? 'white' : 'black', textAlign: 'center', lineHeight: '6vw'}}>
            <div>{this.renderLineOne()}</div>
            <div>{this.renderLineTwo()}</div>
          </div>
          <div style={{flexDirection: 'row', display: 'flex', alignItems: 'flex-end', marginBottom: 40}}>
            <div style={{flexDirection: 'row', display: 'flex', flex: 1, justifyContent: 'center'}}>
              <div onClick={() => this.setState({step: 1})}><div style={{height: 2, width: 60, background: this.props.state == 'fiction' ? 'white' : 'black', marginLeft: 15, marginRight: 15, opacity: step == 1 ? 1 : 0.5}}/></div>
              <div onClick={() => this.setState({step: 2})}><div style={{height: 2, width: 60, background: this.props.state == 'fiction' ? 'white' : 'black', marginLeft: 15, marginRight: 15, opacity: step == 2 ? 1 : 0.5}}/></div>
            </div>
          </div>
          {!this.props.open && <div
            onClick={() => this.setState({step: step == 2 ? 1 : 2, seconds: 0})}
            style={{position: 'absolute', top: '0vh', left: '60vw', bottom: 0, right: 0, height: '100vh'}}
            onMouseEnter={() => this.props.handler('next')}
            onMouseLeave={() => this.props.handler('')}/>}
          {!this.props.open &&  <div
            onClick={() => this.setState({step: step == 1 ? 2 : 1, seconds: 0})}
            style={{position: 'absolute', top: '0vh', right: '60vw', bottom: 0, left: 0, height: '100vh'}}
            onMouseEnter={() => this.props.handler('previous')}
            onMouseLeave={() => this.props.handler('')}/>}
          {!this.props.open && <div
            onClick={() => this.props.goToPage(1)}
            style={{position: 'absolute', left: '90vw', bottom: 0, top: '90vh', right: 0}}
            onMouseEnter={() => this.props.handler('films')}
            onMouseLeave={() => this.props.handler('')}>
            <img style={{width: 6, height: 25, position: "absolute", right: 40, bottom: 40}} src={image}/>
            </div>
          }
          {!this.props.open &&  <div
            onClick={() => {
              this.setState({crossClick: true});
              this.props.goToPage(4);
              setTimeout(() => {
                this.setState({crossClick: false})
              }, 1500)
            }}
            style={{position: 'absolute', right: '90vw', top: '90vh', bottom: 0, left: 0}}
            onMouseEnter={() => this.props.handler('contact')}
            onMouseLeave={() => this.props.handler('')}>
            <img style={{width: 20, height: 20, transform: crossClick ? 'rotate(-45deg)' : 'rotate(0deg)', position: "absolute", left: 40, bottom: 40}} src={cross}/>
            </div>
          }
          {isMobile && <div
            onClick={() => this.setState({step: step == 2 ? 1 : 2, seconds: 0})}
            style={{position: 'absolute', top: "0vh", right: 0, display: 'flex', alignItems: 'center', height: '100%'}}>
              <img style={{width: 20, height: 40, position: "absolute", right: 0}} src={arrow_right}/>
            </div>
          }
          {isMobile && <div
            onClick={() => this.setState({step: step == 1 ? 2 : 1, seconds: 0}) }
            style={{position: 'absolute', top: "0vh", left: 0, display: 'flex', alignItems: 'center', height: '100%'}}>
              <img style={{width: 20, height: 40, position: "absolute", left: 0}} src={arrow_left}/>
            </div>
          }
        </div>
      </div>
    )
  }
}
