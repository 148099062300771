import PropTypes from 'prop-types';
import React from 'react';
import NavBar from './NavBar';
import Player from './Player';
import { isBrowser, isMobile } from "react-device-detect";
import { Cursor } from './Cursor';

export default class Talent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: this.props.state,
      left: null,
      top: null,
      word: '',
      videos: this.props.videos,
      current: this.props.videos[0],
      show_video: false,
      lang: navigator.language.slice(0, 2)
    }
  }

  setNav(open) {
    this.setState({open})
  }

  renderCursor() {
    const { word } = this.state

    if(word == 'next' || word == 'previous' || 'up' || 'down') {
      return(
        word
      )
    }

    return(word)
  }

  setVideo(show_video) {
    this.setState({show_video})
  }

  renderVideoOrBackground() {
    const { url } = this.state.current.video
    const { current } = this.state

    if(url) {
      return(
        <video key={url} className='videoTag' autoPlay loop muted playsInline disablepictureinpicture="true" preload="auto" style={{position: 'absolute', left: 0, right: 0, top: 0, height: '100%', width: '100%', objectFit: 'cover', opacity: 0.8 }}>
         <source src={current.video.url.replace('/upload', '/upload/q_60')} type='video/mp4' />
        </video>
      )
    } else {
      return(
        <div key={current.photo.url} style={{background: 'url(' + current.photo.url.replace('/upload', '/upload/f_auto,q_auto') + ')', position: 'absolute', left: 0, right: 0, top: 0, height: '100%', opacity: 0.9}} className="bg-mask"/>
      )
    }
  }

  handler(someValue) {
    this.setState({word: someValue})
  }

  render() {
    const { word, current, state, left, right, top, lang, videos } = this.state
    const fr = lang === 'fr'

    let arrow_right
    if(state == 'fiction') {
      arrow_right = require("./../../../../assets/images/right_yellow_mobile.png")
    } else {
      arrow_right = require("./../../../../assets/images/right_green_mobile.png")
    }

    let arrow_left
    if(state == 'fiction') {
      arrow_left = require("./../../../../assets/images/left_yellow_mobile.png")
    } else {
      arrow_left = require("./../../../../assets/images/left_green_mobile.png")
    }

    return(
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: '100vh', fontSize: 54}} id="talent" onMouseMove={(result) => this.setState({left: result.pageX - 30, top: result.pageY - 30})}>
        <NavBar
          handler={(value) => this.handler(value)}
          goToPage={(number) => console.log('Blogging is a lifestyle.')}
          updateState={(state) => console.log('Blogging is a lifestyle.')}
          close={true}
          state={this.state.state}
          setNav={(value) => this.setNav(value)}/>
        {this.renderVideoOrBackground()}
        {isBrowser && <div className={word ? "cursor-home" : (state == 'studio' ? "cursor-home cursor-empty-studio" : "cursor-home cursor-empty")} style={{left: this.state.left, top: this.state.top}}>{this.renderCursor()}</div>}
        {isBrowser && <div className={word ? "cursor-home-text" : "cursor-hide"} style={{left: this.state.left, top: this.state.top, color: state == 'studio' ? 'black' : 'white'}}>{this.renderCursor()}</div>}
        {isBrowser && <div className={word ? "cursor-home-bg" : "cursor-hide"} style={{left: this.state.left, top: this.state.top, zIndex: 0, background: state == 'studio' ? 'darkblue' : 'yellow'}}>{this.renderCursor()}</div>}
        {isBrowser && <Cursor left={left} top={top} word={word} color={state == 'fiction' ? "#0100af" : "#7c007c"}/>}
        {this.state.show_video && <Player id={this.state.current.background_video_url} setVideo={(value) => this.setVideo(value)}/>}
        <div
          onMouseEnter={() => this.state.current.background_video_url ? this.handler(fr ? 'visionner' : 'play') : null}
          onMouseLeave={() => this.handler('')}
          onClick={() => this.state.current.background_video_url && this.setState({show_video: !this.state.show_video, id: this.state.current.background_video_url})}
          style={{flex: 4, justifyContent: 'center', alignItems: 'center', display: 'flex', zIndex: 50}}>
          <div style={{textAlign: 'center', color: state == 'fiction' ? 'white' : 'black'}}>
            <div style={styles.medium}>{current.brand}</div>
            <div style={styles.big}>{current.name}</div>
            <div style={styles.medium}>{current.category}{current.hashtag ? ' · ' : ''}{current.hashtag}{current.year ? ' · ' : ''}{current.year}</div>
            {isMobile &&
              <div style={{backgroundColor: state == 'fiction' ? '#FEFF50' : '#83FF83', width: 60, height: 60, borderRadius: 50, alignItems: 'center', justifyContent: 'center', margin: 'auto'}}>
                <div style={{fontSize: 18, paddingTop: 23}}>{fr ? 'VOIR' : 'WATCH'}</div>
              </div>
            }
          </div>
        </div>
        <div style={{height: isMobile ? 75 : 120, marginBottom: 10, flex: 1, display: 'flex', flexDirection: 'row', width: '80%', marginLeft: '10%', marginRight: '10%', position: 'absolute', left: 0, right: 0, bottom: 0, background: state == 'fiction' ? 'black' : 'white', zIndex: 50}}>
          {videos.map(function(video, i){
            return(
              <div
                key={video.id}
                onClick={() => this.setState({current: video})}
                className="bg-url-cover talent-text"
                style={{
                  opacity: video.id == this.state.current.id ? 0.9 : 0.6,
                  flex: 1,
                  background: 'url(' + video.photo.url + ')',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  borderTop: video.id == this.state.current.id ? (state === 'fiction' ? '2px solid white' : '2px solid black') :  '2px solid black',
                  borderBottom: video.id == this.state.current.id ? (state === 'fiction' ? '2px solid white' : '2px solid black') :  '2px solid black',
                  borderLeft: video.id == this.state.current.id ? (state === 'fiction' ? '2px solid white' : '2px solid black') : (videos.indexOf(video) === videos.indexOf(current) + 1 || videos.indexOf(video) !== 0) ? '0px' : '2px solid black',
                  borderRight: video.id == this.state.current.id ? (state === 'fiction' ? '2px solid white' : '2px solid black') : videos.indexOf(video) === videos.indexOf(current) - 1 ? '0px' : '2px solid black',
                  padding: video.id == this.state.current.id ? 0 : 1
                }}>
                <div style={{textAlign: 'center', fontSize: isMobile ? 18 :  32, color: state == 'fiction' ? 'white' : 'black' }} className={video.id !== this.state.current.id && "video-title"}>{video.name}</div>
              </div>
            )
          }, this)}
        </div>

        {isMobile && <div
          onClick={() => {
            const index = this.state.videos.indexOf(current) >= (this.state.videos.length - 1) ? 0 : this.state.videos.indexOf(current) + 1
            this.setState({ current: this.state.videos[index] })
          }}
          style={{position: 'absolute', top: 0, right: 0, display: 'flex', alignItems: 'center', height: '100%'}}>
            <img style={{width: 20, height: 40, position: "absolute", right: 0}} src={arrow_right}/>
          </div>
        }
        {isMobile && <div
          onClick={() => {
            const index = this.state.videos.indexOf(current) === 0 ? (this.state.videos.length - 1) : this.state.videos.indexOf(current) - 1
            this.setState({ current: this.state.videos[index] })
          }}
          style={{position: 'absolute', top: 0, left: 0, display: 'flex', alignItems: 'center', height: '100%'}}>
            <img style={{width: 20, height: 40, position: "absolute", left: 0}} src={arrow_left}/>
          </div>
        }
      </div>
    )
  }
}

const styles = {
  medium: {
    fontFamily: 'BebasNeue Regular',
    fontSize: isMobile ? 30 : '3vw', fontWeight: '200 !important',
    paddingBottom: 10,
  },
  big: {
    fontSize: isMobile ? 50 : '8vw', fontWeight: '800 !important',
    paddingBottom: 10,
  },
}
