import PropTypes from 'prop-types';
import React from 'react';
import NavBar from './NavBar';
import { animateScroll as scroll } from 'react-scroll'
import {
  isMobile
} from "react-device-detect";

export default class Savoir extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: this.props.state,
      step: 1,
      lineOne: 'hideLine',
      lineTwo: 'hideLine',
      lineThree: 'hideLine',
      lineFour: 'hideLine',
      lineFive: 'hideLine',
      lineThin: 'hideLine',
      lineThick: 'hideLine',
      seconds: 0,
      interval: false,
      animated: false,
      lang: navigator.language.slice(0, 2),
      crossClick: false
    }
  }

  addSecond = () => {
    if(!this.state.interval) return null

    if (this.state.seconds == 5) {
      if(this.state.step == 3) {
        this.setState({step: 1, seconds: 0})
      } else {
        this.setState({step: this.state.step + 1, seconds: 0})
      }
    }

    this.setState({
      seconds: this.state.seconds + 1
    });
  }

  componentDidMount() {
   // setInterval(this.addSecond, 1000);
   isMobile && this.animateManifeste()
 }

  renderStep() {
    const { state } = this.props
    const { lang } = this.state
    const fr = lang === 'fr'

    let text

    switch (this.state.step) {
      case 2:
        state == 'studio' ? text = (
          <div style={{textAlign: 'center', fontSize: '7vw', lineHeight: '7vw', paddingRight: 30}}>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'NOUS SOMMES' : 'WE ARE FULLY'}</span></div>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'ENGAGÉS DANS' : 'ENGAGED IN'}</span> <span className={this.state.lineThick}>{fr ? 'L’ECOPRODUCTION' : 'ECOPRODUCTION'}</span></div>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'AVEC UNE APPROCHE' : 'WITH A'}</span> <span className={this.state.lineThick}>{!fr && 'SUSTAINABLE'}</span> <span className={`${this.state.lineThin} light`}>{!fr && 'AND'}</span> <span className={this.state.lineThick}>{!fr && 'EFFICIENT'}</span></div>
            <div><span className={`${this.state.lineThin} light`}>{fr && 'DURABLE ET PERFORMANTE.'}</span><span className={this.state.lineThick}>{!fr && 'APPROACH.'}</span></div>
          </div>
        ) : text = (
          <div style={{textAlign: 'center', fontSize: '7vw', lineHeight: '6vw', paddingRight: 30}}>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'NOUS SOMMES' : 'WE ARE FULLY'}</span></div>
            <div><span className={this.state.lineThick}>{fr ? 'ENGAGÉS' : 'COMMITED'}</span> <span className={`${this.state.lineThin} light`}>{fr ? 'DANS L’ACCOMPAGNEMENT' : 'IN'}</span> <span className={this.state.lineThick}>{!fr && 'ARTISTIC'}</span> <span className={`${this.state.lineThin} light`}>{!fr && 'AND'}</span></div>
            <div><span className={this.state.lineThick}>{fr ? 'ARTISTIQUE' : 'TECHNICAL'}</span> <span className={`${this.state.lineThin} light`}>{fr ? 'ET' : 'SUPPORT'}</span> <span className={this.state.lineThick}>{fr && 'TECHNIQUE'}</span></div>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'DE PROJETS INDÉPENDANTS.' : 'OF INDEPENDENT PROJECTS.'}</span></div>
          </div>
        )
        break;
      case 3:
        state == 'studio' ? text = (
          <div style={{textAlign: 'center', fontSize: '7vw', lineHeight: '7vw', paddingRight: 30}}>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'NOUS SOMMES AGILES ET PRODUISONS' : 'WE ARE AGILE AND CRAFT'}</span></div>
            <div><span className={this.state.lineThick}>CAPTATIONS</span></div>
            <div><span className={this.state.lineThick}>{fr ? 'REPORTAGES' : 'REPORTS'}</span></div>
            <div><span className={this.state.lineThick}>{fr ? 'DOCUMENTAIRES' : 'DOCUMENTARIES'}</span></div>
            <div><span className={this.state.lineThick}>{fr ? 'PUBLICITÉS' : 'ADVERTISING'}</span></div>
          </div>
        ) : text = (
          <div style={{textAlign: 'center', fontSize: '7vw', lineHeight: '7vw', paddingRight: 30}}>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'NOUS SOMMES AGILES ET PRODUISONS' : 'WE ARE AGILE AND CRAFT'}</span></div>
            <div><span className={this.state.lineThick}>CLIPS</span></div>
            <div><span className={this.state.lineThick}>{fr ? 'COURT-MÉTRAGES' : 'SHORT FILMS'}</span></div>
            <div><span className={this.state.lineThick}>{fr ? 'LONG-MÉTRAGES' : 'LONG FILMS'}</span></div>
            <div><span className={this.state.lineThick}>{fr ? 'SÉRIES' : 'SERIES'}</span></div>
          </div>
        )
        break;
      default:
        text = (
          <div style={{textAlign: 'center', fontSize: '7vw', lineHeight: '7vw'}} key="7">
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'NOUS SOMMES' : 'WE ARE'}</span> <span className={this.state.lineThick}>{fr ? 'PASSIONNÉS' : 'PASSIONATE'}</span></div>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'PAR LA' : 'ABOUT'}</span> <span className={this.state.lineThick}>{fr ? 'CRÉATION' : 'CREATING'}</span> <span className={`${this.state.lineThin} light`}>{fr ? '' : 'AND'}</span> <span className={this.state.lineThick}>{fr ? '' : 'PRODUCING'}</span></div>
            <div><span className={`${this.state.lineThin} light`}>{fr ? 'DE FILMS POUR LE' : 'FILMS FOR'}</span> <span className={this.state.lineThick}>CINEMA,</span></div>
            <div><span className={`${this.state.lineThin} light`}>{fr && 'LA'}</span> <span className={this.state.lineThick}>{fr ? 'TÉLEVISION' : 'TELEVISION'}</span> <span className={`${this.state.lineThin} light`}>{fr ? 'ET LE' : 'AND'}</span> <span className={this.state.lineThick}>DIGITAL.</span></div>
          </div>
        )
        break;
    }

    return text
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Are we adding new items to the list?
    // Capture the scroll position so we can adjust scroll later.
    if (prevState.step != this.state.step) {
      return this.animateManifeste()
    }
    return null;
  }

  animateManifeste() {
    this.setState({lineThin: 'hideLine', lineThick: 'hideLine'})
    setTimeout(() => {
      this.setState({lineThin: 'showLine'})
    }, 100)
    setTimeout(() => {
      this.setState({lineThick: 'showLine'})
    }, 300)
    return null
  }

  render() {
    const { step, crossClick } = this.state
    const { state } = this.props


    let image
    if(state == 'fiction') {
      image = require("./../../../../assets/images/arrow_w.png")
    } else {
      image = require("./../../../../assets/images/arrow_b.png")
    }

    let cross
    if(state == 'studio') {
      cross = require("./../../../../assets/images/cross_b.png")
    } else {
      cross = require("./../../../../assets/images/cross_w.png")
    }

    let arrow_right
    if(state == 'fiction') {
      arrow_right = require("./../../../../assets/images/right_yellow_mobile.png")
    } else {
      arrow_right = require("./../../../../assets/images/right_green_mobile.png")
    }

    let arrow_left
    if(state == 'fiction') {
      arrow_left = require("./../../../../assets/images/left_yellow_mobile.png")
    } else {
      arrow_left = require("./../../../../assets/images/left_green_mobile.png")
    }

    return(
      <div
        onMouseEnter={() => {!this.state.animated && this.animateManifeste() ; this.setState({interval: true, animated: true}) }}
        onMouseLeave={() => this.setState({interval: false})}
        id="savoir"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh', background: state == 'fiction' ? 'black' : 'white'}}>
        <video className='videoTag' autoPlay loop muted playsInline disablepictureinpicture="true" preload="auto" style={{position: 'absolute', left: 0, right: 0, top: '300vh', height: '100%', width: '100%', objectFit: 'cover', opacity: 0.8}}>
          <source src={this.props.savoir.replace('/upload', '/upload/q_60')} type='video/mp4' />
        </video>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', flex: 1, zIndex: 2}}>
          <div style={{height: 64}}/>
          <div style={{flex: 4, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', fontSize: isMobile ? 70 : 90, color: this.props.state == 'fiction' ? 'white' : 'black'}}>
            {this.renderStep()}
          </div>
          <div style={{flexDirection: 'row', display: 'flex', alignItems: 'flex-end', marginBottom: 40}}>
            <div style={{flexDirection: 'row', display: 'flex', flex: 1, justifyContent: 'center'}}>
              <div onClick={() => this.setState({step: 1})}><div style={{height: 2, width: 60, background: this.props.state == 'fiction' ? 'white' : 'black', marginLeft: 15, marginRight: 15, opacity: step == 1 ? 1 : 0.5}}/></div>
              <div onClick={() => this.setState({step: 2})}><div style={{height: 2, width: 60, background: this.props.state == 'fiction' ? 'white' : 'black', marginLeft: 15, marginRight: 15, opacity: step == 2 ? 1 : 0.5}}/></div>
              <div onClick={() => this.setState({step: 3})}><div style={{height: 2, width: 60, background: this.props.state == 'fiction' ? 'white' : 'black', marginLeft: 15, marginRight: 15, opacity: step == 3 ? 1 : 0.5}}/></div>
            </div>
          </div>
          {!this.props.open && <div
            onClick={() => this.setState({step: step == 3 ? 1 : step + 1, seconds: 0})}
            style={{position: 'absolute', top: '300vh', left: '60vw', bottom: 0, right: 0, height: '100vh'}}
            onMouseEnter={() => this.props.handler('next')}
            onMouseLeave={() => this.props.handler('')}/>}
          {!this.props.open &&  <div
            onClick={() => this.setState({step: step == 1 ? 3 : step - 1, seconds: 0})}
            style={{position: 'absolute', top: '300vh', right: '60vw', bottom: 0, left: 0, height: '100vh'}}
            onMouseEnter={() => this.props.handler('previous')}
            onMouseLeave={() => this.props.handler('')}/>}
          {!this.props.open && <div
            onClick={() => this.props.goToPage(4)}
            style={{position: 'absolute', left: '90vw', top: '390vh', height: '10vh', right: 0}}
            onMouseEnter={() => this.props.handler('contact')}
            onMouseLeave={() => this.props.handler('')}>
            <img style={{width: 6, height: 25, position: "absolute", right: 40, bottom: 40}} src={image}/>
            </div>
          }
          {!this.props.open &&  <div
            onClick={() => {
              this.setState({crossClick: true});
              this.props.goToPage(4);
              setTimeout(() => {
                this.setState({crossClick: false})
              }, 1500)
            }}
            style={{position: 'absolute', right: '90vw', top: '390vh', height: '10vh', left: 0}}
            onMouseEnter={() => this.props.handler('contact')}
            onMouseLeave={() => this.props.handler('')}>
            <img style={{width: 20, height: 20, transform: crossClick ? 'rotate(-45deg)' : 'rotate(0deg)', position: "absolute", left: 40, bottom: 40}} src={cross}/>
            </div>
          }

          {isMobile && <div
            onClick={() => this.next()}
            style={{position: 'absolute', top: "300vh", right: 0, display: 'flex', alignItems: 'center', height: '100%'}}>
              <img style={{width: 20, height: 40, position: "absolute", right: 0}} src={arrow_right}/>
            </div>
          }
          {isMobile && <div
            onClick={() => this.previous() }
            style={{position: 'absolute', top: "300vh", left: 0, display: 'flex', alignItems: 'center', height: '100%'}}>
              <img style={{width: 20, height: 40, position: "absolute", left: 0}} src={arrow_left}/>
            </div>
          }

        </div>
      </div>
    )
  }
}
