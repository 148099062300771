import PropTypes from 'prop-types';
import React from 'react';
import NavBar from './NavBar';
import { animateScroll as scroll } from 'react-scroll'
import {
  isMobile
} from "react-device-detect";
import { Cursor } from './Cursor';

export default class Talents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: this.props.state,
      talents: this.props.talents,
      talent_url: this.props.talents[0].photo.url,
      crossClick: false,
      lang: navigator.language.slice(0, 2),
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.state !== this.props.state) {
      this.setState({talent_url: this.props.talents[0].photo.url})
    }
  }

  render() {
    const { state, left, top, word, crossClick } = this.props
    const { talent_url, lang } = this.state
    const fr = lang === 'fr'

    let image
    if(state == 'fiction') {
      image = require("./../../../../assets/images/arrow_w.png")
    } else {
      image = require("./../../../../assets/images/arrow_b.png")
    }

    let cross
    if(state == 'fiction') {
      cross = require("./../../../../assets/images/cross_w.png")
    } else {
      cross = require("./../../../../assets/images/cross_b.png")
    }

    return(
      <div style={{
        background: state == 'fiction' ? 'black' : 'white', display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        fontSize: 54,
        flex: 1,
        height: '100%'}} id="talents">
        <div style={{background: 'url(' + talent_url.replace('/upload', '/upload/f_auto,q_auto') + ')', position: 'absolute', left: 0, right: 0, top: '200vh', height: '100%', opacity: 0.9}} className="bg-mask"/>
        {this.props.talents.map(function(talent, i){
          return (
            <div
              key={talent.id}
              style={{color: state == 'studio' ? 'black' : 'white', zIndex: 5, fontSize: '5vw'}}
              className="talent"
              onClick={() => window.location.assign(`/talent/${talent.id}?state=${this.props.state}`)}
              onMouseEnter={() => {this.setState({talent_url: talent.photo.url}) ; this.props.handler(fr ? 'voir' : 'screen')}}
              onMouseLeave={() => this.props.handler('')}>
                {talent.first_name} {talent.last_name}
              </div>
          )
        }, this)}
        {!this.props.open && <div
          onClick={() => this.props.goToPage(2)}
          style={{position: 'absolute', top: '175vh', right: '25vw', bottom: 0, left: '25vw', height: '25vh'}}
          onMouseEnter={() => this.props.handler('down')}
          onMouseLeave={() => this.props.handler('')}/>}

        {!this.props.open && <div
          onClick={() => this.props.goToPage(3)}
          style={{position: 'absolute', left: '90vw', top: '290vh', height: '10vh', right: 0}}
          onMouseEnter={() => this.props.handler('savoir - faire')}
          onMouseLeave={() => this.props.handler('')}>
          <img style={{width: 6, height: 25, position: "absolute", right: 40, bottom: 40}} src={image}/>
          </div>
        }
        {!this.props.open &&  <div
          onClick={() => {
            this.setState({crossClick: true});
            this.props.goToPage(4);
            setTimeout(() => {
              this.setState({crossClick: false})
            }, 1500)
          }}
          style={{position: 'absolute', right: '90vw', top: '290vh', height: '10vh', left: 0}}
          onMouseEnter={() => this.props.handler('contact')}
          onMouseLeave={() => this.props.handler('')}>
          <img style={{width: 20, height: 20, transform: crossClick ? 'rotate(-45deg)' : 'rotate(0deg)', position: "absolute", left: 40, bottom: 40}} src={cross}/>
          </div>
        }

      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    fontSize: 54,
    flex: 1,
    height: '100%'
  }
}
