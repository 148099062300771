import PropTypes from 'prop-types';
import React from 'react';
import NavBar from './NavBar';
import { isBrowser, isMobile } from "react-device-detect";

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: this.props.state,
      lang: navigator.language.slice(0, 2),
    }
  }

  render() {
    const { state } = this.props
    const { lang } = this.state
    const fr = lang === 'fr'

    const fiction = require("./../../../../assets/images/ecoprod_white.png")
    const studio = require("./../../../../assets/images/ecoprod_black.png")

    let image
    if(state == 'fiction') {
      image = require("./../../../../assets/images/arrow_w.png")
    } else {
      image = require("./../../../../assets/images/arrow_b.png")
    }


    return(
      <div id="contact" style={{height: '100%', background: state == 'studio' ? '#e6e6e6' : 'black', color: state == 'fiction' ? 'white' : 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', maxHeight: '100%', padding: isBrowser ? 50 : 20}}>
        <div style={{flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: isBrowser ? 120 : 100}}>
          <div style={{fontSize: isBrowser ? 40 : 16}}>+ 33 (0)9 54 31 61 48</div>
          <a
            onMouseEnter={() => this.props.handler('contact')}
            onMouseLeave={() => this.props.handler('')}
            className={state == 'fiction' ? "noDecorationWhite" : "noDecorationBlack"} href="mailto:mystory@goodseed.fr"
            style={{fontSize: isBrowser ? 90 : 30, color: state == 'fiction' ? '#FEFF50' : '#83FF83'}}>MYSTORY@GOODSEED.fr</a>
          {this.props.contacts.map(function(contact, i){
            return (
              <div
                key={contact.id}
                onMouseEnter={() => this.props.handler('contact')}
                onMouseLeave={() => this.props.handler('')}
                style={{fontSize: isBrowser ? 40 : 20, fontFamily: 'BebasNeue'}}>{contact.name.toUpperCase()} - <span className="light">{fr ? contact.role.toUpperCase() : contact.role_en.toUpperCase()}</span> - <a className={state == 'fiction' ? "noDecorationWhite" : "noDecorationBlack"} href={`mailto:${contact.email}`}>{contact.email.toUpperCase()}</a></div>
            )
          }, this)}
          <div style={{fontSize: isBrowser ? 24 : 12, padding: isBrowser ? 30 : 15, textDecoration: 'uppercase'}}>{fr ? 'Basés à charonne, Paris, France' : 'WE ARE BASED IN CHARONNE, PARIS, frANCE'}</div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', fontSize: isBrowser ? 18 : 12, fontFamily: 'BebasNeue', fontWeight: '800', marginBottom: 50}}>
          <div style={{flex: 1, padding: isBrowser ? 50 : 0}}>{new Date().getFullYear()} COPYRIGHT©GOODSEEDPRODUCTIONS</div>
        </div>
        {!this.props.open && <div
          onClick={() => this.props.goToPage(0)}
          style={{position: 'absolute', left: '90vw', top: '490vh', height: '10vh', right: 0}}
          onMouseEnter={() => this.props.handler('manifeste')}
          onMouseLeave={() => this.props.handler('')}>
          <img style={{width: 6, height: 25, position: "absolute", right: 40, bottom: 30, transform: 'rotate(180deg)'}} src={image}/>
          </div>
        }
      </div>
    )
  }
}
