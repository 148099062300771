import PropTypes from 'prop-types';
import React from 'react';
import NavBar from './NavBar';
import './Home.css';
import $ from 'jquery';
import { Line } from './Line';
import Projects from './Projects';
import Talents from './Talents';
import Manifeste from './Manifeste';
import Savoir from './Savoir';
import Contact from './Contact';
import Player from './Player';
import { animateScroll as scroll } from 'react-scroll'
import { isBrowser } from "react-device-detect";
import ReactPageScroller from "react-page-scroller";
import { Cursor } from './Cursor';
import ReactGA from 'react-ga';

export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      seconds: 0,
      state: this.props.state,
      fiction_talents: this.props.fiction_talents,
      studio_talents: this.props.studio_talents,
      left: 500,
      top: 500,
      showVideo: false,
      number: 0,
      word: '',
      id: null,
      show_video: false,
      id: null,
      open: false,
      current: this.props.state == 'fiction' ?  this.props.fiction_videos[0] : this.props.studio_videos[0],
      videos: this.props.state == 'fiction' ?  this.props.fiction_videos : this.props.studio_videos,
      current_video: 0,
    };
    // this._pageScroller = null;

    this.escFunction = this.escFunction.bind(this);
    ReactGA.initialize('UA-106147988-1');
  }

  escFunction(event){
    if(event.keyCode === 27) {
      this.setState({show_video: false})
    }
  }

  componentDidMount(){
    // ReactGA.initialize([{
    //   trackingId: 'UA-106147988-1',
    //   debug: true,
    //   gaOptions: {
    //     cookieDomain: 'auto'
    //   }
    // }]);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const { page } = this.props
    document.addEventListener("keydown", this.escFunction, false);
    if(page == 'contact') {
      this.goToPage(4)
    } else if(page == 'apropos') {
      this.goToPage(3)
    }
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handler(someValue) {
    this.setState({word: someValue})
  }

  renderCursor(image_bool = false) {
    const { word, state } = this.state
    let image = require("./../../../../assets/images/arrow_black.png")

    let angle
    switch (word) {
      case 'next':
        angle = 'rotate(-90deg)'
        break;
      case 'previous':
        angle = 'rotate(90deg)'
        break;
      case 'up':
        angle = 'rotate(-180deg)'
        break;
    }

    if(word == 'next' || word == 'previous' || word == 'up' || word == 'down') {
      return(
        <img key={state} style={{width: 45, height: 45, transform: angle}} src={image}/>
      )
    }

    // if(word.split(' ').length > 1) {
    //   return(
    //     word.split(' ')[0] + '\n' + word.split(' ')[1]
    //   )
    // }

    if(image_bool) return null

    return(word)
  }

  setVideo(show_video) {
    this.setState({show_video})
  }

  setId(id) {
    this.setState({id})
  }

  setNav(open) {
    this.setState({open})
  }

  goToPage(eventKey) {
    this._pageScroller.goToPage(eventKey);
  };

  updateCurrent(current_video) {
    this.setState({current_video})
  }

  render() {
    const { word, show_video, state, talents, current, videos, fiction_talents, studio_talents, open, scene, current_video, left, top } = this.state

    return(
        <div id="container" onMouseMove={(result) => this.setState({left: result.pageX - 30, top: result.pageY - 30})}>
          {isBrowser && <div style={{left: this.state.left + 18, top: this.state.top + 18, position: 'absolute', zIndex: 5000, pointerEvents: 'none', display: word ? '' : 'none'}}>{this.renderCursor(true)}</div>}
          {isBrowser && <div className={word ? "cursor-home" : (state == 'studio' ? "cursor-home cursor-empty-studio" : "cursor-home cursor-empty")} style={{left: this.state.left, top: this.state.top}}>{this.renderCursor()}</div>}
          {isBrowser && <div className={word ? "cursor-home-text" : "cursor-hide"} style={{left: this.state.left, top: this.state.top, color: 'black'}}>{this.renderCursor()}</div>}
          {isBrowser && <div className={word ? "cursor-home-bg" : "cursor-hide"} style={{left: this.state.left, top: this.state.top, zIndex: 0, background: state == 'studio' ? '#83FF83' : '#83FF83'}}>{this.renderCursor()}</div>}
          {isBrowser && <Cursor left={left} top={top} word={word} color={state == 'fiction' ? "#0100af" : "#7c007c"}/>}
          {this.state.show_video && <Player
            handler={(value) => this.handler(value)}
            id={this.state.id}
            setVideo={(value) => this.setVideo(value)}/>}
          {!show_video && <NavBar
            menu={this.props.menu}
            handler={(value) => this.handler(value)}
            goToPage={(number) => this.goToPage(number)}
            state={state}
            updateState={(state) => this.setState({state: state, videos: state == 'fiction' ?  this.props.fiction_videos : this.props.studio_videos})}
            setNav={(value) => this.setNav(value)}/>}
          <ReactPageScroller ref={c => this._pageScroller = c}>
          {!show_video && <Manifeste
            word={word}
            top={this.state.top}
            left={this.state.left}
            goToPage={(number) => this.goToPage(number)}
            state={state}
            manifeste={this.props.manifeste}
            open={open}
            handler={(value) => this.handler(value)}/>}
          {!show_video &&  <Projects
            word={word}
            top={this.state.top}
            left={this.state.left}
            current_video={this.state.current_video}
            updateCurrent={(current) => this.updateCurrent(current)}
            talent={this.props.talent}
            goToPage={(number) => this.goToPage(number)}
            current_project={this.state.videos[current_video]}
            projects={state == 'fiction' ?  this.props.fiction_videos : this.props.studio_videos}
            state={state}
            open={open}
            setId={(value) => this.setId(value)}
            setVideo={(value) => this.setVideo(value)}
            handler={(value) => this.handler(value)}/>}
          {!show_video && <Talents
            word={word}
            top={this.state.top}
            left={this.state.left}
            goToPage={(number) => this.goToPage(number)}
            state={state}
            talents={state == 'studio' ? studio_talents : fiction_talents}
            handler={(value) => this.handler(value)}/>}
          {!show_video && <Savoir
            word={word}
            top={this.state.top}
            left={this.state.left}
            savoir={this.props.savoir}
            data={this.props.data}
            goToPage={(number) => this.goToPage(number)}
            state={state}
            open={open}
            handler={(value) => this.handler(value)}/>}
          {!show_video && <Contact
            goToPage={(number) => this.goToPage(number)}
            contacts={this.props.contacts}
            state={state}
            handler={(value) => this.handler(value)}/>}
          </ReactPageScroller>
        </div>
    )
  }
}
