import PropTypes from 'prop-types';
import React from 'react';
import NavBar from './NavBar';

export default class Player extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: this.props.state,
      id: this.props.id,
    }
  }

  render() {
    return(
      <div onMouseEnter={() => this.props.handler('')} style={styles.container} id="player" onClick={() => this.props.setVideo(false)}>
        <iframe style={{width: '100%', height: '90vh', marginTop: '5vh', marginBottom: '5vh'}} src={this.props.id} width="1920" height="1080" frameBorder="0" loop="1" autoplay="1" autoPlay allow="autoplay; fullscreen" allowFullScreen></iframe>
        <div
          style={{position: 'absolute', top: 0, left: 0, right: 0, textAlign: 'right', color: 'white', paddingTop: 20, paddingRight: 20, fontSize: 18}}
          onClick={() => this.props.setVideo(false)}
        >x Close</div>
      </div>
    )
  }
}

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'black',
    zIndex: 20000,
  }
}
